@import "../../asset/css/theme.scss";
.UploadList{
    &-top{
        display: flex;
        justify-content: space-between;
    }
    &-Table{
        padding-top: 20px;
    }
    .MovType1{
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        // text-align: center;
    }
    .MovType2{
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #FF5252;
        letter-spacing: 0;
        // text-align: center;
    }
}
.coverVideo{
    width: 250px;
    height: 140px;
    border-radius: 5px;
    position: relative;
    // object-fit: cover;
    .coverVideo-play{
        width: 51px;
        height: 51px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
    .coverVideo-cover{
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
}
.AlertVideo{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    // width: 800px;
    // height: 400px;
    // background: #CCC;

}