#root {
    height: 100%;
}

#root>div {
    height: 100%;

}

.homepage {
    height: 100%;
    ;
    overflow: hidden;
}

#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.header-box {
    text-align: center;
    padding: 20px 0;
    border-top:24px solid #f0f2f5 ;
}

.headname {
    padding-top: 10px;
}

.head-logo {
    img {
        width: 150px;
    }
}

.header {
    background: #fff;
    padding: 0 25px;

    .head-fight {
        text-align: right;
        .h-name{
            margin-right: 15px;
        }
        .loginout {
            color: #0994FF;
            cursor: pointer;
        }
    }
}
.sider-left{
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background-color: #fff;
    }
    .home-link-item{
        color: #333;
    }
    .ant-menu-item-selected .anticon-mail{
        color: #333;
    }
    .ant-menu-item-active .anticon-mail{
        color: #333;
    }
    .ant-menu-item{
        border-bottom: 1px solid #f0f2f5;
    }
    .anticon{
        color: #333;
    }
    .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
        color: #333;
    
    }
    .ant-menu-item-active a, .ant-menu-item-active a:hover{
        color: #333;
    
    }
    .ant-menu-item-selected{
        background-color: #fff !important;
    }
    .ant-menu-item-selected::after{
        border-right: 3px solid #FF487D;
    }
}