button{
    border: none;
}
// $color-main     : #FED900;
// $color-title    : #40320D;
$color-main     : #FF487D;
$color-title    : #FFFFFF;


// $color-subtitle : #8C846E;
$color-subtitle : #999999;
$color-back     : #F6F8F9;
$color-place    : #EDF2F5;
$color-line     : #F4F4F4;
$color-promotion: #B26100;
$color-save     : #0396FF;
// $color-label1   : #F6F8F9;
// $color-label2   : #EDF2F5;
$color-label3   : #FFFADB;
// $color-subback  : #FFFFFF;
$color-remind   : #FF5252;
$color-progressBack: #FFF4B5;
$color-unselected: #E9E9E9;
$color-progressLine: #FF850A;
$color-diskLight : #FF933A;
$color-diskGray  : #969696;
$color-white     : #FFFFFF;
$color-upgrade   : #FF850A;
$color-upgradeBG : #FFF4B5;
$color-nextLevel : #655600;
$color-NoHg-title: #40320D;

$color-popularize-color:#FAF18E;

$color-EEEEEE : #EEEEEE;
$color-000000 : #000000;
$color-D6E4EF : #D6E4EF;
$color-FF850A : #FF850A;
$color-FDF7F1 : #FDF7F1;
$color-57C476 : #57C476;
$color-FF3636 : #FF3636;
$color-F7F7F8 : #F7F7F8;
$color-003600 : #003600;
$color-FF4d4D : #FF4d4D;
$color-FFFDEF : #FFFDEF;
$color-121213 : #121213;
$color-323232 : #323232;
$color-CA9962 : #CA9962;
$color-DDB368 : #DDB368;
$color-FFFAF5 : #FFFAF5;
$color-445180 : #445180;
$color-FFD241 : #FFD241;
$color-40320D : #40320D;
$color-333333 : #333333;
$color-848484 : #848484;
$color-EDF2F4 : #EDF2F4;
$color-999999 : #999999;

$hd: 2px; // 基本单位

$font-size-28: 14 * $hd;
$font-size-32: 16 * $hd;

$font-size-14: 7 * $hd;
$font-size-16: 8 * $hd;
$font-size-20: 10 * $hd;
$font-size-22: 11 * $hd;
$font-size-24: 12 * $hd;
$font-size-26: 13 * $hd;
$font-size-28: 14 * $hd;
$font-size-30: 15 * $hd;
$font-size-32: 16 * $hd;
$font-size-34: 17 * $hd;
$font-size-36: 18 * $hd;
$font-size-40: 20 * $hd;
$font-size-48: 24 * $hd;
$font-size-68: 34 * $hd;
$font-size-70: 35 * $hd;

// 水平间距
$h-spacing-4: 2 * $hd;
$h-spacing-10: 5 * $hd;
$h-spacing-16: 8 * $hd;
$h-spacing-20: 10 * $hd;
$h-spacing-30: 15 * $hd;
$h-spacing-40: 20 * $hd;
$h-spacing-50: 25 * $hd;
$h-spacing-60: 30 * $hd;
$h-spacing-80: 40 * $hd;

// 垂直间距
$v-spacing-4: 2 * $hd;
$v-spacing-10: 5 * $hd;
$v-spacing-12: 6 * $hd;
$v-spacing-20: 10 * $hd;
$v-spacing-24: 12 * $hd;
$v-spacing-30: 15 * $hd;
$v-spacing-48: 24 * $hd;
$v-spacing-100: 50 * $hd;
// 圆角
$radius-4: 2 * $hd;
$radius-8: 4 * $hd;
$radius-12: 6 * $hd;
$radius-16: 8 * $hd;
$radius-24: 12 * $hd;
$radius-30: 15 * $hd;
$radius-circle: 50%;

$icon-size-30: 15 * $hd;
$icon-size-36: 18 * $hd;
$icon-size-40: 20 * $hd;
$icon-size-44: 22 * $hd;
$icon-size-72: 36 * $hd;
$icon-size-80 : 40 * $hd;

$border-width-6: 3 * $hd;

// 字体类型
$font-family-PingFang-SC: PingFangSC;
$font-family-PingFang-RG: PingFangSC-Regular;
$font-family-PingFangSC-Semibold: PingFangSC-Semibold;
$font-family-PingFangSC-Medium: PingFangSC-Medium;

$font-weight-600: 600;



