.CPWebPlayer{
  
    .video-js{
        width: 750px;
        height: 424px;
        // margin-top: 30px;
        position: relative;
        
        .vjs-tech{
        width: 750px;
        
        }
         
        .vjs-big-play-button{
            font-size: 2.5em;
            line-height: 2.3em;
            height: 2.5em;
            width: 2.5em;
            -webkit-border-radius: 2.5em;
            -moz-border-radius: 2.5em;
            border-radius: 2.5em;
            background-color: #73859f;
            background-color: rgba(115,133,159,.5);
            margin-top: -1.25em;
            margin-left: -1.75em;
            .vjs-icon-placeholder:before {
                font-size: 64px;
            }
        }
        // /* 加载圆圈 */
        .vjs-loading-spinner {
            font-size: 2.5em;
            width: 2em;
            height: 2em;
            border-radius: 1em;
            margin-top: -1em;
            margin-left: -1.5em;
        }

        .vjs-big-play-button {

        }
    }
}
